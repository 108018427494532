<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#42275a"
      spinner="spinner" />
    <v-layout class="mainfont" wrap justify-center pb-7>
      <v-flex xs11 pt-4>
        <v-layout wrap>
          <v-flex xs12>
            <v-card class="pb-4">
              <v-layout wrap justify-start>
                <v-flex xs12 pl-3 pt-3 pb-4 class="bgcustom">
                  <span style="font-size: 25px;color:white">Application Number - {{ list.applicationNo }}</span>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap pb-3>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs5 lg3 text-left pl-0 pl-lg-3>
                  <span class="subheading" style="font-size: 16px;">
                    Applicant Name
                  </span>
                  <br />
                  <span>{{ list.applicantName }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left>
                  <span class="subheading" style="font-size: 16px;">
                    District
                  </span>
                  <br />
                  <span>{{ list.applicantDistrict }}</span>
                </v-flex>

                <v-flex xs5 lg3 text-left>
                  <span class="subheading" style="font-size: 16px;">
                    Email

                  </span>
                  <br />
                  <span>{{ list.applicantEmail }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left>
                  <span class="subheading" style="font-size: 16px;">
                    Mobile Number

                  </span>
                  <br />
                  <span>{{ list.applicantMobileNo }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pl-0 pl-lg-3 pt-4>
                  <span class="subheading" style="font-size: 16px;">
                    Address

                  </span>
                  <br />
                  <span>{{ list.applicantAddress }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px;">
                    Pincode

                  </span>
                  <br />
                  <span>{{ list.applicantPinCode }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px;">
                    Id Type
                  </span>
                  <br />
                  <span>{{ list.idType }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px;">
                    Id Card No
                  </span>
                  <br />
                  <span>{{ list.idcardNo }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4 pl-0 pl-lg-3>
                  <span class="subheading" style="font-size: 16px;">
                    Nature Of Application

                  </span>
                  <br />
                  <span>{{ list.natureOfApplication }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px;">
                    Id Proof
                  </span>&nbsp;

                  <v-btn class="bgcustom" dark download :href="baseURL + 'file/get/' + idProof" target="_blank"><span
                      style="text-transform: none; font-family: mainfont; font-size: 12px;">View
                    </span></v-btn>
                  <br />
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px;">
                    Progress
                  </span>&nbsp;

                  <v-btn class="bgcustom" dark @click="$router.push('/records?id=' + id)"><span
                        style="text-transform: none; font-family: mainfont; font-size: 12px;">Progress
                      </span></v-btn>
                  <!-- <v-btn class="bgcustom" dark @click="navigateToRecords(id)">
                    <span style="text-transform: none; font-family: mainfont; font-size: 12px;">Progress</span>
                  </v-btn> -->

                  <br />
                </v-flex>
                <v-flex xs12 pa-5>
                  <v-card elevation="0" style="border: 1px solid black; border-radius: 0px;">
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-layout wrap class="subheading">
                          <v-flex xs2 text-center pt-2>
                            Location
                          </v-flex>


                          <v-flex xs3 text-center pt-2>
                            From Date
                          </v-flex>
                          <v-flex xs3 text-center pt-2>
                            To Date
                          </v-flex>
                          <v-flex xs2 text-center pt-2>
                            No Of Members
                          </v-flex>
                          <v-flex xs2 text-center pt-2>
                            No Of Vehicles
                          </v-flex>

                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap pt-4 pb-4>
                      <v-flex xs12>
                        <v-divider></v-divider>
                      </v-flex>
                    </v-layout>
                    <v-layout pb-3 wrap v-for="(item, i) in filmShootingDetails" :key="i">
                      <v-flex xs2 text-center pt-2>
                        {{ item.location }}
                      </v-flex>

                      <v-flex xs3 text-center pt-2>
                        {{ formatDate(item.fromDate) }}
                      </v-flex>
                      <v-flex xs3 text-center pt-2>
                        {{ formatDate(item.toDate) }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.noOfMembers }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.noOfVehicles }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs2>
            <v-btn color="primary" @click="goBack">
              <span style="text-transform: none; font-size: 15px; color: white;">
                GO BACK
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialogVisible2" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont">
            Upload Report
          </span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-file-input class="mainfont" v-model="reportFile" label="Choose Report file"></v-file-input>


          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadReport()" color="primary">Yes</v-btn>
          <v-btn @click="dialogVisible2 = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogVisible" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont">
            Upload Magasar
          </span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-file-input class="mainfont" v-model="mahasarFile" label="Choose Mahasar file"></v-file-input>


          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadMahasar()" color="primary">Yes</v-btn>
          <v-btn @click="dialogVisible = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont">
            Upload Agreement

          </span>
        </v-card-title>
        <v-card-text>
          <v-card>

          </v-card>
          <v-file-input class="mainfont" v-model="file" label="Choose a file"></v-file-input>
        </v-card-text>
        <v-card-actions>

          <v-btn class="mainfont" @click="uploadAgreement()" color="primary">Yes</v-btn>
          <v-btn @click="openDialog = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="true" persistent v-model="deletedialog" max-width="600px">
      <v-card>
        <v-card-title class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">Are you sure you want
          to Cancel?</v-card-title>
        <v-card-actions class="d-flex justify-center text-center">
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="deletedialog = false" class="mainfont">Cancel</v-btn>
          <v-btn color="red" class="mainfont" text @click="deleteItem()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import axios from "axios";
// import CryptoJS from "crypto-js";

export default {
  components: {
  },
  data() {
    // const encryptedId = this.$route.query.id;
    // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
    const today = new Date().toISOString().substr(0, 10);
    return {
      dialogVisible2: false,
      idNo: null,
      startDate: today,
      showSnackBar: false,
      isChecked: false,
      deletedialog: false,
      openDialog: false,
      reportFile: null,
      file: null,
      mahasarFile: null,
      appLoading: false,
      msg: "",
      id: this.$route.query.id,
      // id: decryptedId,
      name: null,
      dialogVisible: false,
      barlist: [],
      list: {},
      leaderdetails: [],
      idProof: "",
      filmShootingDetails: [],
    };
  },
  mounted() {
    this.getList();
  },
  created() {
    this.uType = localStorage.getItem("uType");
  },

  computed: {
    user() {
      const uType = localStorage.getItem("uType");
      if (uType === "777") {
        return "admin";
      }

      else if (uType === "772") {
        return "user";

      }

      else if (uType === "773") {
        return "range";

      }
      else if (uType === "7741") {
        return "section";
      }
      else if (uType === "7742") {
        return "superclerk";
      }
      else {
        return "division";
      }
    },

  },

  methods: {
  //   navigateToRecords(id) {
  //   const encryptedId = CryptoJS.AES.encrypt(id, 'encryptionSecret').toString();
  //   this.$router.push({
  //     path: '/records',
  //     query: {
  //       id: encryptedId
  //     }
  //   });
  // },
    goBack() {
      // Use the router to navigate back to the previous page
      this.$router.go(-1);
    },
    forwardToClerk2() {
      axios({
        url: "/range/forward/clerk",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,

        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            // window.location.reload();
            this.$router.push({ path: "/rangebookings" });

          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    uploadAgreement() {
      const formData = new FormData();
      formData.append('userAgreement', this.file);
      formData.append('appid', this.id);
      axios.post(`/user/agreement`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': localStorage.getItem('token'),
        },
      })
        .then(response => {
          console.log(response.data);
          this.openDialog = false;
          location.reload();
        })
        .catch(error => {
          console.error(error);
        });
    },

    uploadReport() {
      const formData = new FormData();
      formData.append('rangeReport', this.reportFile);
      formData.append('appid', this.id);
      axios.post(`/report/upload`, formData, {
        headers: {

          'token': localStorage.getItem('token'),
        },
        data: {
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(response => {
          console.log(response.data);
          this.dialogVisible = false,
            location.reload();
        })
        .catch(error => {
          // Handle error
          console.error(error);
        });
    },
    uploadMahasar() {
      const formData = new FormData();
      formData.append('rangeMahassar', this.mahasarFile);
      formData.append('appid', this.id);
      axios.post(`/mahassar/upload`, formData, {
        headers: {

          'token': localStorage.getItem('token'),
        },
        data: {
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(response => {
          console.log(response.data);
          this.dialogVisible = false,
            location.reload();
        })
        .catch(error => {
          // Handle error
          console.error(error);
        });
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const selectedFile = event.target.files[0];
      console.log('Selected file:', selectedFile);

    },
    forwardToClerk() {
      axios({
        url: "/check/forward",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    feePayment() {
      axios({
        url: "/fees/submission",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.forwardToClerk();
            // this.deletedialog = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem() {

      axios({
        url: "/cancel/application",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,

        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getAcknowledgment() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/acknowledgement/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write('<iframe width="100%" height="100%" src="' + url + '" type="application/pdf"></iframe>');
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },
    getApplication() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/application/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write('<iframe width="100%" height="100%" src="' + url + '" type="application/pdf"></iframe>');
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },

    getAgreement() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/agreement/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write('<iframe width="100%" height="100%" src="' + url + '" type="application/pdf"></iframe>');
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },

    // getAgreement() {
    //   const token = localStorage.getItem("token");
    //   const downloadUrl = "/agreement/pdf";
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     params: {
    //       appid: this.$route.query.id,
    //     },
    //     responseType: "blob",
    //   };

    //   axios
    //     .get(downloadUrl, config)
    //     .then((response) => {
    //       const blob = new Blob([response.data], { type: 'application/pdf' });
    //       const url = window.URL.createObjectURL(blob);

    //       // Set the window location to the PDF URL
    //       window.location.href = url;
    //     })
    //     .catch((error) => {
    //       console.error("Error downloading acknowledgement:", error);
    //     });
    // },


    getList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/single/application",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.list = response.data.data;
            this.filmShootingDetails = this.list.filmShootingDetails;
            this.memberDetails = this.list.memberDetails;
            this.idProof = this.list.idProof;
            this.reqLetter = this.list.reqLetter;
            this.participantfile = this.list.participantfile;
            this.leaderIdProof = this.list.leaderIdProof;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
  },
};
</script>
  
  
<style>
.subheading {
  font-family: mainfont;
  color: #42275a;
  text-transform: none;
  font-size: 17px;
  font-weight: bold;
}

.heading {
  font-family: mainfont;
  color: white;
  text-transform: none;
  font-size: 22px;

}
</style>